import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const useAutoScrollTop = (isEnable, pathname) => {
  const prevPath = useRef({ value: null }).current;
  const router = useRouter();

  const scrollToTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    if (!isEnable) return;

    if (!prevPath.value || prevPath.value !== pathname) {
      router.events.on('routeChangeComplete', scrollToTop);
    }

    prevPath.value = pathname;

    return () => {
      router.events.off('routeChangeComplete', scrollToTop);
    };
  }, [isEnable, pathname]);
};
